import React, {useMemo, useState, useEffect, useContext, useCallback} from 'react'
import {useQuery, useMutation, useSubscription, gql} from '@apollo/client'
import {DragDropContext, DropResult} from 'react-beautiful-dnd'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {usePageVisibility} from 'react-page-visibility'

import {
  Queue,
  AppointmentEditor,
  AppointmentImporter,
  AppointmentEditorProps,
  PromptField,
  Empty,
  filterAppointments,
  queueRequirements,
  sortAppointments,
  hydrateAppointment,
  QueueHeading,
} from '../components/appointments'
import {Skeleton} from '../components'
import {Appointment, AppointmentEvent, Status, Role, User, getStatusDisplay} from '../models'
import Page from '../components/page'
import {AppContext} from '../context'
import {Banner, Button, ConfirmDialog} from '../components/tailwind'
import {
  CalendarIcon,
  ChairIcon,
  ClockIcon,
  DocumentAddIconSmall,
  PlusCircleIconSmall,
  ToothIcon,
} from '../components/icon'
import {AppointmentEventInput} from '../graphql/Global'
import {APPOINTMENT_EVENT_SELECT_FRAGMENT, APPOINTMENT_SELECT_FRAGMENT} from '../queries'
import {IndexGetAppointmentsQuery} from '../graphql/IndexGetAppointmentsQuery'
import {IndexAddAppointmentEventMutation} from '../graphql/IndexAddAppointmentEventMutation'
import {IndexNewAppointmentSubscription} from '../graphql/IndexNewAppointmentSubscription'
import {IndexNewAppointmentEventSubscription} from '../graphql/IndexNewAppointmentEventSubscription'
import {IndexSplitAppointmentMutation} from '../graphql/IndexSplitAppointmentMutation'

export const GET_APPOINTMENTS = gql`
  query IndexGetAppointmentsQuery {
    appointments {
      ...AppointmentSelectFragment
    }
    tenant {
      id
      users {
        id
        name
        picture
        roles
      }
      rooms {
        id
        name
        order
        locationId
      }
      tags {
        id
        name
        color
        level
      }
      rules {
        id
        threshold
        color
        status
      }
    }
  }
  ${APPOINTMENT_SELECT_FRAGMENT}
`

export const ADD_APPOINTMENT_EVENT = gql`
  mutation IndexAddAppointmentEventMutation($tid: String!, $input: AppointmentEventInput!) {
    addAppointmentEvent(tid: $tid, input: $input) {
      ...AppointmentEventSelectFragment
    }
  }
  ${APPOINTMENT_EVENT_SELECT_FRAGMENT}
`

export const SPLIT_APPOINTMENT = gql`
  mutation IndexSplitAppointmentMutation($input: AppointmentSplitInput!) {
    splitAppointment(input: $input) {
      ...AppointmentSelectFragment
    }
  }
  ${APPOINTMENT_SELECT_FRAGMENT}
`

export const NEW_APPOINTMENT_SUBSCRIPTION = gql`
  subscription IndexNewAppointmentSubscription($tid: String!) {
    newAppointment(tid: $tid) {
      ...AppointmentSelectFragment
    }
  }
  ${APPOINTMENT_SELECT_FRAGMENT}
`

export const NEW_APPOINTMENT_EVENT_SUBSCRIPTION = gql`
  subscription IndexNewAppointmentEventSubscription($tid: String!) {
    newAppointmentEvent(tid: $tid) {
      ...AppointmentEventSelectFragment
    }
  }
  ${APPOINTMENT_EVENT_SELECT_FRAGMENT}
`

const getProviderLastName = (appointment: Appointment) => appointment.provider?.name.split(' ')[1] || ''

const IndexContent: React.FC = () => {
  const [appointmentEditorProps, setAppointmentEditorProps] = useState<AppointmentEditorProps>()
  const [showAppointmentImporter, setShowAppointmentImporter] = useState(false)
  const [events, setEvents] = useState<Record<string, AppointmentEvent[]>>({})
  const [newAppointments, setNewAppointments] = useState<Appointment[]>([])
  const {error, data, loading, refetch} = useQuery<IndexGetAppointmentsQuery>(GET_APPOINTMENTS, {
    fetchPolicy: 'cache-and-network',
  })
  const [addAppointmentEvent] = useMutation<IndexAddAppointmentEventMutation>(ADD_APPOINTMENT_EVENT)
  const [splitAppointmentMutation] = useMutation<IndexSplitAppointmentMutation>(SPLIT_APPOINTMENT)
  const [promptAppointment, setPromptAppointment] = useState<Appointment>()
  const [deleteAppointment, setDeleteAppointment] = useState<Appointment>()
  const [completeAppointment, setCompleteAppointment] = useState<Appointment>()
  const [moveAppointment, setMoveAppointment] = useState<Appointment>()
  const [moveAppointmentEvent, setMoveAppointmentEvent] = useState<AppointmentEventInput>()
  const [moveWarning, setMoveWarning] = useState<string | undefined>()
  const isVisible = usePageVisibility() as boolean

  useEffect(() => {
    if (isVisible) {
      refetch()
    }
  }, [isVisible, refetch])

  const {users, rules, id: tid} = data?.tenant ?? {}
  const rolePresent = useCallback(
    (role: Role) => {
      return users && users.find((u) => u.roles.includes(role) && !u.roles.includes(Role.NON_USER))
    },
    [users]
  )

  const doctorPresent = rolePresent(Role.DOCTOR)
  const hygienePresent = rolePresent(Role.HYGIENE)
  const treatmentPresent = rolePresent(Role.TREATMENT)
  const assistantPresent = rolePresent(Role.ASSISTANT)

  if (error) console.log(error)

  const {currentLocation} = useContext(AppContext)

  const appointments: Appointment[] = useMemo(
    () =>
      data?.appointments &&
      [...data?.appointments, ...newAppointments]
        .map((appointment: Appointment) => hydrateAppointment(appointment, events, promptAppointment))
        .filter((a) => a.locationId === currentLocation?.id),
    [data?.appointments, newAppointments, events, currentLocation?.id, promptAppointment]
  )

  const {data: newAppointmentData} = useSubscription<IndexNewAppointmentSubscription>(NEW_APPOINTMENT_SUBSCRIPTION, {
    variables: {tid},
  })
  useEffect(() => {
    if (!newAppointmentData) return
    const {newAppointment} = newAppointmentData

    const current = newAppointments.find((a) => a.id === newAppointment.id)
    if (current) return

    setNewAppointments([...newAppointments, newAppointment])
  }, [newAppointmentData, newAppointments])

  const {data: newAppointmentEventData} = useSubscription<IndexNewAppointmentEventSubscription>(
    NEW_APPOINTMENT_EVENT_SUBSCRIPTION,
    {variables: {tid}}
  )
  useEffect(() => {
    if (!newAppointmentEventData) return
    const {newAppointmentEvent} = newAppointmentEventData

    const currentEvents = events[newAppointmentEvent.appointmentId]
    const current = currentEvents?.slice().sort((a, b) => b.createdAt - a.createdAt)[0]
    if (current?.createdAt >= newAppointmentEvent.createdAt) return

    setEvents({
      ...events,
      [newAppointmentEvent.appointmentId]: currentEvents
        ? [...currentEvents, newAppointmentEvent]
        : [newAppointmentEvent],
    })
  }, [newAppointmentEventData, events])

  const pending = useMemo(() => filterAppointments(appointments, Status.PENDING), [appointments])
  const seated = useMemo(() => filterAppointments(appointments, Status.SEATED), [appointments])
  const waiting = useMemo(() => filterAppointments(appointments, Status.WAITING), [appointments])
  const claimed = useMemo(() => filterAppointments(appointments, Status.CLAIMED), [appointments])
  const claimedByDoctor = useMemo(() => claimed.filter((a) => a.provider?.roles?.includes(Role.DOCTOR)), [claimed])
  const claimedByOther = useMemo(() => claimed.filter((a) => !a.provider?.roles?.includes(Role.DOCTOR)), [claimed])

  const sortedClaimed = useMemo(
    () =>
      claimedByDoctor
        .slice()
        .sort((a, b) => getProviderLastName(a).localeCompare(getProviderLastName(b)))
        .concat(claimedByOther.slice().sort((a, b) => getProviderLastName(a).localeCompare(getProviderLastName(b)))),
    [claimedByOther, claimedByDoctor]
  )
  const sortedWaiting = useMemo(() => sortAppointments(waiting), [waiting])
  const sortedSeated = useMemo(() => sortAppointments(seated), [seated])
  const sortedPending = useMemo(
    () => pending.slice().sort((a, b) => a.scheduled - b.scheduled || a.patient.localeCompare(b.patient)),
    [pending]
  )

  const noAppointments = pending.length + seated.length + waiting.length + claimed.length === 0

  const queues: Record<string, Appointment[]> = {
    [Status.CLAIMED]: sortedClaimed,
    [Status.WAITING]: sortedWaiting,
    [Status.SEATED]: sortedSeated,
    [Status.PENDING]: sortedPending,
  }

  // eslint-disable-next-line complexity
  const move = (appointment: Appointment, status: Status) => {
    const input = {
      appointmentId: appointment.id,
      status,
    }

    const promptFields = queueRequirements[status]
    if (promptFields) {
      const hasRole = (role: Role) => appointment.provider?.roles?.includes(role)
      const isClaimed = input.status === Status.CLAIMED
      const isFirstAvailable = hasRole(Role.FIRST_AVAILABLE)
      const isFirstAvailableDoctor = isFirstAvailable && hasRole(Role.DOCTOR)
      const isFirstAvailableHygiene = isFirstAvailable && hasRole(Role.HYGIENE)
      const isFirstAvailableTreatment = isFirstAvailable && hasRole(Role.TREATMENT)
      const isFirstAvailableAssistant = isFirstAvailable && hasRole(Role.ASSISTANT)

      const doctorFilter = (p: User) =>
        p?.roles.includes(Role.DOCTOR) && (!p?.roles.includes(Role.NON_USER) || !doctorPresent)
      const hygieneFilter = (p: User) =>
        p?.roles.includes(Role.HYGIENE) && (!p?.roles.includes(Role.NON_USER) || !hygienePresent)
      const treatmentFilter = (p: User) =>
        p?.roles.includes(Role.TREATMENT) && (!p?.roles.includes(Role.NON_USER) || !treatmentPresent)
      const assistantFilter = (p: User) =>
        p?.roles.includes(Role.ASSISTANT) && (!p?.roles.includes(Role.NON_USER) || !assistantPresent)
      const defaultProviderFilter = (p: User) =>
        doctorFilter(p) || hygieneFilter(p) || treatmentFilter(p) || assistantFilter(p)

      const providerFilter = isClaimed
        ? isFirstAvailableDoctor
          ? doctorFilter
          : isFirstAvailableHygiene
          ? hygieneFilter
          : isFirstAvailableTreatment
          ? treatmentFilter
          : isFirstAvailableAssistant
          ? assistantFilter
          : defaultProviderFilter
        : undefined

      const promptForProvider =
        hasRole(Role.FIRST_AVAILABLE) &&
        ((hasRole(Role.DOCTOR) && doctorPresent) ||
          (hasRole(Role.HYGIENE) && hygienePresent) ||
          (hasRole(Role.TREATMENT) && treatmentPresent) ||
          (hasRole(Role.ASSISTANT) && assistantPresent))

      const prompt = () => {
        const appt = {
          ...appointment,
          desiredStatus: input.status,
        }
        setAppointmentEditorProps({
          appointment: appt,
          promptFields,
          providerFilter,
          prompt: true,
          onDismiss: handleAppointmentPromptDismissal,
        })
        setPromptAppointment(appt)
      }

      for (const field of promptFields) {
        switch (field) {
          case PromptField.Room:
            if (!appointment.roomId) {
              prompt()
              return
            }

            break
          case PromptField.Provider:
            if (!appointment.providerId || (isClaimed && promptForProvider)) {
              prompt()
              return
            }

            break
          case PromptField.Requesting:
            if (!appointment.requestingId) {
              prompt()
              return
            }

            break
          case PromptField.Notes:
            if (!appointment.notes?.trim()) {
              prompt()
              return
            }

            break

          default:
            break
        }
      }
    }

    const existingAppointmentInRoom = appointments.find(
      (a) =>
        !a.completed &&
        a.id !== appointment.id &&
        a.roomId &&
        a.roomId === appointment.roomId &&
        a.parentId !== appointment.id &&
        appointment.parentId !== a.id
    )

    if (existingAppointmentInRoom) {
      setMoveWarning(
        `There's an active appointment with ${existingAppointmentInRoom.patient} in ${
          existingAppointmentInRoom.room?.name || 'this room'
        } already.\n\nAre you sure you want to proceed?`
      )
      setMoveAppointment({
        ...appointment,
        desiredStatus: status,
      })
      return
    }

    if (status === Status.CLAIMED) {
      const existingAppointmentClaimedByProvider = appointments.find(
        (a) =>
          !a.completed &&
          a.status === Status.CLAIMED &&
          a.id !== appointment.id &&
          a.roomId !== appointment.roomId &&
          a.providerId &&
          a.providerId === appointment.providerId
      )

      if (existingAppointmentClaimedByProvider) {
        setMoveWarning(
          `There's an in-progress appointment with ${existingAppointmentClaimedByProvider.patient} assigned to ${
            existingAppointmentClaimedByProvider.provider?.name || 'this provider'
          } already.\n\nAre you sure you want to proceed?`
        )
        setMoveAppointment({
          ...appointment,
          desiredStatus: status,
        })
        return
      }
    }

    // :D
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setEvents((e) => {
      return {
        ...e,
        [appointment.id]: [
          ...(e[appointment.id] ?? []),
          {
            ...input,
            createdAt: Date.now() / 1000,
            provider: appointment.provider,
            requesting: appointment.requesting,
            room: appointment.room,
          },
        ],
      }
    })

    addAppointmentEvent({
      variables: {
        tid: appointment.tid,
        input,
      },
    })
  }

  const handleDragEnd = (result: DropResult): void => {
    const {source, destination} = result

    if (!destination) return
    if (source.droppableId === destination.droppableId) return

    const moved = queues[source.droppableId][source.index]
    if (!moved) return

    move(moved, Status[destination.droppableId as keyof typeof Status])
  }

  const handleImportClick = () => {
    setShowAppointmentImporter(true)
  }

  const handleAppointmentImportDismissal = () => {
    setShowAppointmentImporter(false)
  }

  const handleEditComplete = async (event: AppointmentEventInput) => {
    if (event) {
      const appointment = appointments.find((a) => a.id === event.appointmentId)
      const existingAppointmentInRoom = appointments.find(
        (a) =>
          !a.completed &&
          a.id !== appointment.id &&
          a.roomId &&
          a.roomId === (event.roomId || appointment.roomId) &&
          a.parentId !== appointment.id &&
          appointment.parentId !== a.id
      )

      if (existingAppointmentInRoom) {
        setMoveWarning(
          `There's an active appointment with ${existingAppointmentInRoom.patient} in ${
            existingAppointmentInRoom.room?.name || 'this room'
          } already.\n\nAre you sure you want to proceed?`
        )
        setMoveAppointmentEvent(event)
        return
      }

      if (event.status === Status.CLAIMED) {
        const existingAppointmentClaimedByProvider = appointments.find(
          (a) =>
            !a.completed &&
            a.status === Status.CLAIMED &&
            a.id !== appointment.id &&
            a.roomId !== (event.roomId || appointment.roomId) &&
            a.providerId &&
            a.providerId === (event.providerId || appointment.providerId)
        )

        if (existingAppointmentClaimedByProvider) {
          setMoveWarning(
            `There's an in-progress appointment with ${existingAppointmentClaimedByProvider.patient} assigned to ${
              existingAppointmentClaimedByProvider.provider?.name || 'this provider'
            } already.\n\nAre you sure you want to proceed?`
          )
          setMoveAppointmentEvent(event)
          return
        }
      }

      await addAppointmentEvent({
        variables: {
          tid,
          input: {
            ...event,
            provider: undefined,
            requesting: undefined,
            room: undefined,
            tags: undefined,
          },
        },
      })

      // :D
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setEvents((e) => {
        return {
          ...e,
          [event.appointmentId]: [
            ...(e[event.appointmentId] ?? []),
            {
              ...event,
              createdAt: Date.now() / 1000,
            },
          ],
        }
      })
    }
  }

  const handleAppointmentEditorDismissal = async (event: AppointmentEventInput) => {
    await handleEditComplete(event)
    setAppointmentEditorProps(undefined)
  }

  const handleNewAppointmentClick = () => {
    setAppointmentEditorProps({onDismiss: handleAppointmentEditorDismissal})
  }

  const handleAppointmentPromptDismissal = async (event: AppointmentEventInput) => {
    await handleEditComplete(event)

    setAppointmentEditorProps(undefined)
    setPromptAppointment(undefined)
  }

  const handleEdit = (appointment: Appointment) => {
    const promptFields = queueRequirements[appointment.status]
    const providerFilter =
      appointment.status === Status.CLAIMED
        ? (p: User) =>
            (p.roles.includes(Role.DOCTOR) && (!p.roles.includes(Role.NON_USER) || !doctorPresent)) ||
            (p.roles.includes(Role.HYGIENE) && (!p.roles.includes(Role.NON_USER) || !hygienePresent)) ||
            (p.roles.includes(Role.TREATMENT) && (!p.roles.includes(Role.NON_USER) || !treatmentPresent)) ||
            (p.roles.includes(Role.ASSISTANT) && (!p.roles.includes(Role.NON_USER) || !assistantPresent))
        : undefined
    setAppointmentEditorProps({
      appointment,
      promptFields,
      providerFilter,
      onDismiss: handleAppointmentEditorDismissal,
    })
  }

  const handleDelete = (appointment: Appointment) => {
    // Need confirmation first
    setDeleteAppointment(appointment)
  }

  const handleDeleteConfirmation = async (result: boolean) => {
    if (result && deleteAppointment) {
      await addAppointmentEvent({
        variables: {
          tid: deleteAppointment.tid,
          input: {
            appointmentId: deleteAppointment.id,
            status: Status.DELETED,
          },
        },
      })
    }

    setDeleteAppointment(undefined)
  }

  const handleComplete = (appointment: Appointment) => {
    // Need confirmation first
    setCompleteAppointment(appointment)
  }

  const handleCompleteConfirmation = async (result: boolean) => {
    if (result && completeAppointment) {
      // :D
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setEvents((e) => {
        return {
          ...e,
          [completeAppointment.id]: [
            ...(e[completeAppointment.id] ?? []),
            {
              appointmentId: completeAppointment.id,
              status: Status.COMPLETE,
              createdAt: Date.now() / 1000,
            },
          ],
        }
      })

      await addAppointmentEvent({
        variables: {
          tid: completeAppointment.tid,
          input: {
            appointmentId: completeAppointment.id,
            status: Status.COMPLETE,
          },
        },
      })
    }

    setCompleteAppointment(undefined)
  }

  const handleSplit = (appointment: Appointment) => {
    const prompt = () => {
      const appt = {
        ...appointment,
        desiredStatus: Status.WAITING,
      }
      setAppointmentEditorProps({
        appointment: appt,
        promptFields: queueRequirements[Status.WAITING],
        prompt: true,
        onDismiss: handleAppointmentPromptForSplitDismissal,
      })
      setPromptAppointment(appt)
    }

    prompt()
  }

  const handleAppointmentPromptForSplitDismissal = async (event: AppointmentEventInput) => {
    if (event) {
      const {
        data: {splitAppointment: newAppointment},
      } = await splitAppointmentMutation({
        variables: {
          input: {
            appointmentId: event.appointmentId,
            providerId: event.providerId,
            requestingId: event.requestingId,
            important: event.important,
            notes: event.notes,
            tagIds: event.tagIds,
          },
        },
      })

      setNewAppointments([...newAppointments, newAppointment])
      setEvents((e) => {
        return {
          ...e,
          [newAppointment.id]: [...(e[newAppointment.id] ?? []), ...newAppointment.events],
        }
      })
    }

    setAppointmentEditorProps(undefined)
    setPromptAppointment(undefined)
  }

  const handleMoveConfirmation = async (result: boolean) => {
    if (result && moveAppointment) {
      // :D
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setEvents((e) => {
        return {
          ...e,
          [moveAppointment.id]: [
            ...(e[moveAppointment.id] ?? []),
            {
              appointmentId: moveAppointment.id,
              status: moveAppointment.desiredStatus,
              createdAt: Date.now() / 1000,
            },
          ],
        }
      })

      await addAppointmentEvent({
        variables: {
          tid: moveAppointment.tid,
          input: {
            appointmentId: moveAppointment.id,
            status: moveAppointment.desiredStatus,
          },
        },
      })
    }

    if (result && moveAppointmentEvent) {
      await addAppointmentEvent({
        variables: {
          tid,
          input: {
            ...moveAppointmentEvent,
            provider: undefined,
            requesting: undefined,
            room: undefined,
            tags: undefined,
          },
        },
      })

      // :D
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setEvents((e) => {
        return {
          ...e,
          [moveAppointmentEvent.appointmentId]: [
            ...(e[moveAppointmentEvent.appointmentId] ?? []),
            {
              ...moveAppointmentEvent,
              createdAt: Date.now() / 1000,
            },
          ],
        }
      })
    }

    setMoveWarning(undefined)
    setMoveAppointment(undefined)
    setMoveAppointmentEvent(undefined)
  }

  const handleClaimed = (appointment: Appointment) => {
    move(appointment, Status.CLAIMED)
  }

  const handleWaiting = (appointment: Appointment) => {
    move(appointment, Status.WAITING)
  }

  const handleSeated = (appointment: Appointment) => {
    move(appointment, Status.SEATED)
  }

  const handlePending = (appointment: Appointment) => {
    move(appointment, Status.PENDING)
  }

  const scheduledActions = [
    {
      'data-test': 'import-button',
      className: 'hidden sm:block',
      children: 'Import',
      secondary: true,
      icon: DocumentAddIconSmall,
      onClick: handleImportClick,
    },
    {
      'data-test': 'new-appointment-button',
      children: 'New appointment',
      icon: PlusCircleIconSmall,
      onClick: handleNewAppointmentClick,
    },
  ]

  return loading ? (
    <Skeleton />
  ) : (
    <>
      {!noAppointments && (
        <DragDropContext onDragEnd={handleDragEnd}>
          <QueueHeading>
            <ToothIcon className="w-6 h-6" />
            <div>{getStatusDisplay(Status.CLAIMED)}</div>
          </QueueHeading>
          <Queue
            compact
            data-test="claimed-queue"
            status={Status.CLAIMED}
            rules={rules}
            data={sortedClaimed}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onComplete={handleComplete}
            onSplit={handleSplit}
            onWaitingChange={handleWaiting}
            onSeated={handleSeated}
            onPending={handlePending}
          />
          <QueueHeading>
            <ClockIcon className="w-6 h-6" />
            <div>{getStatusDisplay(Status.WAITING)}</div>
          </QueueHeading>
          <Queue
            data-test="waiting-queue"
            status={Status.WAITING}
            rules={rules}
            data={sortedWaiting}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onComplete={handleComplete}
            onSplit={handleSplit}
            onClaimed={handleClaimed}
            onSeated={handleSeated}
            onPending={handlePending}
          />
          <QueueHeading>
            <ChairIcon className="w-6 h-6" />
            <div>{getStatusDisplay(Status.SEATED)}</div>
          </QueueHeading>
          <Queue
            data-test="seated-queue"
            status={Status.SEATED}
            rules={rules}
            data={sortedSeated}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onComplete={handleComplete}
            onClaimed={handleClaimed}
            onWaitingChange={handleWaiting}
            onPending={handlePending}
          />
          <QueueHeading actions={scheduledActions}>
            <CalendarIcon className="w-6 h-6" />
            <div>{getStatusDisplay(Status.PENDING)}</div>
          </QueueHeading>
          <Queue
            collapsible
            data-test="pending-queue"
            status={Status.PENDING}
            rules={rules}
            data={sortedPending}
            onEdit={handleEdit}
            onDelete={handleDelete}
            onClaimed={handleClaimed}
            onWaitingChange={handleWaiting}
            onSeated={handleSeated}
          />
          <Button size="small" className="block sm:hidden" {...scheduledActions[1]} />
        </DragDropContext>
      )}
      {noAppointments && (
        <Empty
          data-test="empty"
          actions={scheduledActions}
          title={`No scheduled appointments at ${currentLocation?.name}`}
        />
      )}

      {appointmentEditorProps && <AppointmentEditor data-test="appointment-editor" {...appointmentEditorProps} />}
      {showAppointmentImporter && (
        <AppointmentImporter data-test="appointment-importer" onDismiss={handleAppointmentImportDismissal} />
      )}
      {deleteAppointment && (
        <ConfirmDialog title="Confirm Delete" onDismiss={handleDeleteConfirmation}>
          <h1 className="text-gray-700">Are you sure you want to delete this appointment?</h1>
          <Banner type="error" content="This action cannot be undone" />
        </ConfirmDialog>
      )}
      {completeAppointment && (
        <ConfirmDialog title="Confirm Complete" onDismiss={handleCompleteConfirmation}>
          <h1 className="text-gray-700">Are you sure you want to complete this appointment?</h1>
          <Banner type="error" content="This action cannot be undone" />
        </ConfirmDialog>
      )}
      {moveWarning && (
        <ConfirmDialog title="Confirm Change" onDismiss={handleMoveConfirmation}>
          <h1 className="text-gray-700 whitespace-pre">{moveWarning}</h1>
        </ConfirmDialog>
      )}
    </>
  )
}

const IndexPage: React.FunctionComponent = () => {
  return (
    <Page title="Queue">
      <IndexContent />
    </Page>
  )
}

export default IndexPage
